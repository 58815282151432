import { useRouter } from "vue-router";
import { useSession } from "@/store/session.store";
import { useKids } from "@/store/kids.store";
import { ref } from "vue";
import { Credential } from "@/types/Auth";

export function useLoginPage() {
  const router = useRouter();

  const session = useSession();
  const kids = useKids();
  const credential = ref<Credential>({ login: "", pwd: "" });

  async function auth() {
    try {
      await session.login(credential.value, true);
      await kids.loadKids();

      const firstKid = kids.getFirst();
      const kidId = firstKid?.id || 0;

      await router.push({ name: "home", params: { id: kidId } });
    } catch {
      //  PASS
    }
  }

  return {
    credential,
    session,
    auth
  };
}
