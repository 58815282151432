
import { defineComponent } from "vue";

export default defineComponent({
  name: "PInput",
  emits: ["update:modelValue"],
  props: {
    modelValue: { type: String },
    label: { type: String },
    type: { type: String, default: "text" }
  },

  setup(props) {
    const uuid = Math.random()
      .toString(36)
      .substr(2, 9);

    return { props, uuid };
  }
});
