
import { defineComponent, PropType } from "vue";
import { RouteParamsRaw, useRouter } from "vue-router";

export default defineComponent({
  name: "PCard",

  props: {
    title: { type: String },
    desc: { type: String },
    flat: { type: Boolean },
    to: { type: Object as PropType<Partial<RouteParamsRaw>> }
  },

  setup(props) {
    const router = useRouter();

    const pushTo = () => {
      if (!props.to) return;
      return router.push(props.to);
    };

    return { props, pushTo };
  }
});
