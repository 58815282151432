
import { defineComponent } from "vue";

import PCard from "@/components/PCard.vue";
import { useLoginPage } from "@/composable/useLoginPage";
import MButton from "@/components/ui/mobile/buttons/MButton.vue";
import PInput from "@/components/PInput.vue";

export default defineComponent({
  name: "Login",
  components: { PInput, MButton, PCard },

  setup() {
    const { session, auth, credential } = useLoginPage();
    return {
      error: session.error,
      credential,
      auth
    };
  }
});
