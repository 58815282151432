
import { defineComponent } from "vue";

export default defineComponent({
  name: "MButton",
  props: {
    outlined: { type: Boolean },
    flat: { type: Boolean },
    full: { type: Boolean },
    large: { type: Boolean },
    disabled: { type: Boolean }
  }
});
